<template>
  <HomeMobile v-if="isMobileOrTablet"  :data="data"></HomeMobile>
  <HomeDesktop v-else  :data="data"></HomeDesktop>
</template>

<script setup>
import { getHomeData } from "~/api";
const { isMobileOrTablet } = useDevice();
console.log('[device type]:',  isMobileOrTablet)
const data = await getHomeData();
useSeo(data.value)
</script>
<style lang="scss">
:root {
   --yy-card-gradient: linear-gradient(0deg, #FFFFFF 85%,  #E3FFF6);
  --jz-card-gradient: linear-gradient(0deg, #FFFFFF 85%, #EDF6FF);
  --cj-card-gradient:  linear-gradient(0deg, #FFFFFF 85%, #FFF6ED);
}

html.dark {
  --yy-card-gradient: linear-gradient(180deg, #25433A 0%, #232337 50%);
  --jz-card-gradient: linear-gradient(180deg, #262664 0%, #232337 50%);
  --cj-card-gradient: linear-gradient(180deg, #402F1F 0%, #232337 50%);
}
</style>
