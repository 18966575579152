<template>
  <div class="box">
    <div class="container">
      <LazySearchBox is-mobile/>
    </div>
    <div class="kemu-box">
      <ul class="category-box">
        <li v-for="item in category">
          <a :href="item.url" target="_blank">
            <img :src="item.img"/>
            <span>{{ item.name }}</span>
          </a>
        </li>
      </ul>
      <div class="segment">
        <el-segmented v-model="value"
                      @change="handleChange"
                      :options="options"
                      name="tabs"
                      size="large" block/>
        <div class="swiper-box" >
          <CategoryQuestions class="wrap-item" v-for="(item, index) in data?.classList"
                             is-mobile
                             :style="{display: index === activeIndex ? 'inline-block' : 'none'}"
                             :category="item.name"
                             :kemu-list="item.levelclasslist"/>
        </div>
      </div>
    </div>
    <div class="search">
      <div class="left-card-box">
        <NuxtLink :to="linkTo('/zhidao')" v-wave>
          <h6>连接问题与答案</h6>
          <h4>问&答</h4>
          <h5>有问题就一定有答案</h5>
        </NuxtLink>
        <NuxtLink :to="linkTo('/smarthome')" v-wave>
          <h6>使用指南轻松上手</h6>
          <h4>智能家居</h4>
          <h5>智能家居使用说明</h5>
        </NuxtLink>
      </div>
    </div>
    <div class="tiku-card">
      <CategoryCard  v-for="item in data?.classList"
                     is-mobile
                     :key="item.id"
                     :category="item"/>
    </div>
    <CategoryTopic class="topic-box" type="1" :topic-list="data?.kstklist"/>
    <CategoryTopic class="topic-box" type="2" :topic-list="data?.wangkelist"/>
    <CategoryTopic class="topic-box" type="3" :topic-list="data?.zhidaolist"/>
  </div>
</template>
<script setup>
const { data } = defineProps({
  data: {
    type: Object,
    required: true,
    default: {}
  }
})
const value = ref(data.classList[0].name)
const activeIndex = ref(0); // 当前滚动到的tabs
const category = ref([
  {name: '网校', img: 'https://static.ppkao.com/www/images/icon-wangxiao.png', url: 'https://wangxiao.ppkao.com'},
  {name: '搜题', img: 'https://static.ppkao.com/www/images/icon-h5.png', url: 'https://h5.ppkao.com'},
  {name: '小程序', img: 'https://static.ppkao.com/www/images/icon-xcx.png', url: data.xcxlink},
  {name: 'AI题库', img: 'https://static.ppkao.com/www/images/icon-ai.png', url: '/kstkai'},
])

const options = data.classList.map(item => item.name);
const handleChange = (item) => {
  activeIndex.value = options.findIndex(x => x === item);
}
</script>
<style scoped lang="scss">
@use "../../assets/styles/home" as *;
.topic-box {
  margin-top: 12px;
}
.kemu-box {
  border-radius: 10px;
  background-color: var(--card-bg-color);
  padding: 0 10px;
  margin-top: 8px;
   .category-box {
     @include flex-bet-center;
     padding: 20px 10px;
     li {
       a {
         @include flex-col-center;
         img {
           width: 50px;
         }
         span {
           @include font(var(--primary-text-color), 14px, 400);
           margin-top: 6px;
         }
       }
     }
   }
  .el-segmented {
    --el-segmented-item-selected-color: var(--btn-search-text-color);
    --el-segmented-item-selected-bg-color: var(--primary-color);
    --el-segmented-color: var(--primary-text-color);
    --el-border-radius-base: 8px;
    --el-segmented-bg-color: var(--segment-color);
  }
}
.swiper-box {
  white-space: nowrap;
  display: flex; /* 改为使用 flex 布局 */
  transition: transform 0.3s ease; /* 动画过渡效果 */
  will-change: transform; /* 提高性能 */
  overflow: hidden;
  width: 100%;
  .wrap-item {
    width: 100%;
  }
}
.box .search .left-card-box {
  @include flex-bet;
  width: 100%;
  a:first-child {
    margin-right: 6px;
    margin-bottom: 0;
  }
  a:nth-child(1)::before {
    background-position: right -10px center;
    background-size: contain;
  }
  a:nth-child(2)::before {
    background-position: right -20px center;
    background-size: contain;

  }
  a {
    padding: 8px;
    height: 110px;
    h6 {
      background: var(--h6-bg);
      border-radius: 4px;
      padding: 6px;
      align-self: flex-start;
      width: fit-content;
      font-size: 12px;
    }
    h4 {
      font-size: 16px;
      margin: 20px 0 10px 0;
    }
    h5 {
      font-size: 12px;
    }
  }
}
.box .tiku-card {
  margin-top: 12px;
}
</style>
