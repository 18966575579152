<template>
  <div :class="{'side-box': !props.isMobile}">
    <div class="title" v-if="!props.isMobile">
      <img :src="theme?.img"/>
      <span>{{ props.category }}</span>
    </div>
    <ul :class="{'tiku-list': true, 'mobile-list': props.isMobile}">
      <li v-for="(item, index) in kemuList">
        <NuxtLink :to="linkTo(item)" :title="item.name">
          <img v-if="props.isMobile ? index < 3 : index % 5 === 0" src="https://static.ppkao.com/www/images/hot.png" title="热门题库" alt="热门题库">
          <span>{{ item.name }}</span>
        </NuxtLink>
      </li>
    </ul>
    <div class="search-box" v-if="!props.isMobile">
      <input placeholder="请输入想找的题库"
             v-model="word"
             @keydown.enter.prevent="handleSearch"/>
      <button v-wave @click="handleSearch">搜索</button>
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
  category: {
    type: String,
    required: true,
  },
  kemuList: {
    type: Array,
    required: true,
    default: []
  },
  isMobile: {
    type: Boolean,
    default: false
  }
});

const theme = computed(() => useCategory(props?.category));// 配色方案
const word = ref('');

const handleSearch = (event) => {
  if (word.value.length >= 2) {
    navigateTo({path: '/so', query: {q: word.value, type: 2}})
    word.value = '';
  }
};
</script>

<style scoped lang="scss">
.side-box {
  border-radius: 8px;
  border: 2px solid var(--border-color);
  overflow: hidden;
  height: 228px;
  transition: height .2s;
  &:hover {
    height: 300px;
    box-shadow: 0 36px 35px 0 rgba(0,0,0,0.2);
  }
  .title {
    margin-left: 20px;
    @include flex-y-center();
    margin-top: 10px;
    span {
      @include font(var(--title-color), 20px, 600);
      margin-left: 10px;
    }
  }
}
.search-box {
  margin: 0 20px 20px;
  @include flex-bet-center();
  padding: 0 6px 0 20px;
  background: var(--search-bg-color);
  border-radius: 23px;
  height: 46px;
  border: 1px solid var(--search-border-color);
  input {
    height: 100%;
    flex: 1;
    color: var(--title-color);
    &::placeholder {
      font-size: 14px;
      color: #9D9DB0;
    }
  }
  button {
    @include btn(64px, 38px, var(--btn-search-color), 20px);
    color: var(--btn-search-text-color);
  }
}
.mobile-list {
  height: auto !important;
  flex-direction: inherit !important;
  padding: 0 8px !important;
  justify-content: space-between;
  li {
    margin-right: 0 !important;
    span {
      display: block !important;
    }
  }
}
.tiku-list {
  display: flex;
  flex-direction: column;
  align-content: space-between;
  height: 150px;
  flex-wrap: wrap;
  margin: 28px 0 12px 0;
  padding: 0 22px;
  li {
    margin-right: 16px;
    margin-bottom: 16px;
    position: relative;
    span {
      @include font(var(--title-color), 14px, 500);
      @include text-ellipsis-multiple(1);
      width: 84px;
    }
    img {
      position: absolute;
      top: -12px;
      left: -8px;
    }
  }
}
</style>
