<template>
  <div class="card">
    <div class="head">
      <img :src="theme?.img" :alt="theme?.text"/>
      <div class="title">
        <h2>{{ theme?.text }}</h2>
        <span :style="{ background: theme?.fontColor, '-webkit-background-clip': 'text' }">{{ theme?.text }}</span>
      </div>
    </div>
    <div class="body" :style="{ background: cardBg }">
      <ul class="page-indicator">
        <li v-for="(item, index) in page"
            :key="index"
            :style="{background: index === activeIndex ? theme?.color : theme?.defaultColor }"
            @mouseover="activeIndex = index"></li>
      </ul>
      <section>
        <ul class="topic-list">
          <li v-for="(item, index) in topicList" :key="item.id" :class="getPageClass(index)">
            <img src="https://static.ppkao.com/www/images/icon-topic.png">
            <NuxtLink :to="linkTo(item)" class="t-e">
              {{ item.title }}
            </NuxtLink>
          </li>
        </ul>
      </section>
    </div>
    <template v-if="$device.isDesktop">
      <div class="circle-deg-1"></div>
      <div class="circle-deg-2"></div>
    </template>
  </div>
</template>

<script setup>

const {type, topicList} = defineProps({
  type: {
    type: String,
    required: true,
    default: 1
  },
  topicList: {
    type: Array,
    required: true,
    default: []
  }
});
const theme = computed(() => useTopicType(type));// 配色方案

const cardBg = computed(() => `url("${theme.value?.bgImg}") no-repeat calc(100% - 4px) 14px, ${theme.value?.bg}`);

const pageCount = Math.ceil(topicList.length / 7);
const page = computed(() => [...Array(pageCount).keys()]);
const activeIndex = ref(0);

let timer = null;
const autoSlide = () => {
  activeIndex.value = (activeIndex.value + 1) % pageCount;
};

const getPageClass = (index) => {
  return Math.floor(index / 7) === activeIndex.value ? 'active' : 'hidden';
};

onMounted(() => {
  if (process.client) {
    timer = setInterval(autoSlide, 3000);
  }
});
onUnmounted(()=> {
  clearInterval(timer)
})
</script>

<style scoped lang="scss">
.card {
  @include flex-col;
  position: relative;
  width: var(--topic-size);

  .head {
    @include flex-y-center;
    position: absolute;
    top: 0;

    .title {
      @include flex-y-center;
      position: relative;
      margin-left: var(--topic-title-ml);

      h2 {
        font-weight: bold;
        font-size: var(--topic-card-font);
        color: var(--stroke-color);
        position: absolute;
        top: 0;
        left: 0;
        -webkit-text-stroke: 5px var(--stroke-color);
        text-stroke: 3px var(--stroke-color);
        z-index: 1;
      }

      span {
        font-weight: bold;
        font-size: var(--topic-card-font-size);
        background: linear-gradient(180deg, #52A3FF, #F4F9FF);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        z-index: 2;
        display: inline-block;
        letter-spacing: 2px;
      }
    }
  }

  .body {
    width:  var(--topic-size);
    border: 2px solid var(--border-color);
    border-radius: 8px;
    clip-path: polygon(48.5% 0%,100% 0%,100% 35%,100% 67%,100% 100%,50% 100%,0% 100%,0% 70%,0% 13%,43% 13%);
    .page-indicator {
      @include flex-y-center;
      position: absolute;
      right: 20px;
      top: 24px;
      cursor: pointer;

      li {
        margin-left: 4px;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background: #BCC8E1;
      }
    }

    .topic-list {
      @include flex-bet-center;
      flex-wrap: wrap;
      padding: 60px var(--card-padding) 0;
      .hidden {
        display: none;
      }

      .active {
        display: flex;
      }
      li {
        border-top: 2px dashed var(--slider-color);
        margin-right: 22px;
        width: var(--topic-wrap-w);
        transition: opacity 0.2s ease-in-out;
        display: flex;
        align-items: center;
        &:nth-child(7n + 1) {
          border-top: none;
        }

        &:nth-child(-n+3) {
          a {
            color: #FB3F3F;
          }
        }

        a {
          width: 100%;
          padding: var(--card-topic-a-p) 0;
          @include font(var(--title-color), var(--global-font-size));
          display: block;

        }
        img {
          margin-right: 10px;
        }
      }

    }
  }

  .circle-deg-1 {
    width: 165px;
    height: 46px;
    transform: skewX(336deg);
    transform-origin: top right;
    position: absolute;
    left: 21px;
    top: 12px;
    border: 2px solid var(--border-color);
    border-top: none;
    border-left: none;
    border-radius: 12px 0 12px 0;
  }

  .circle-deg-2 {
    width: 198px;
    height: 17px;
    transform: skewX(336deg);
    transform-origin: top left;
    position: absolute;
    right: 4px;
    border: 2px solid var(--border-color);
    border-right: none;
    border-bottom: none;
    border-radius: 8px 0 8px 0;
  }
}
</style>
